// Generated by Framer (5ec741e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gSmfjEWkH", "LWrlPvQXM", "N9rKH0JL3"];

const serializationHash = "framer-JHAuM"

const variantClassNames = {gSmfjEWkH: "framer-v-115a0cu", LWrlPvQXM: "framer-v-1emgdmg", N9rKH0JL3: "framer-v-sgybdf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Desktop (Small)": "LWrlPvQXM", Desktop: "gSmfjEWkH", Tablet: "N9rKH0JL3"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gSmfjEWkH"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gSmfjEWkH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.framer.com/marketplace/creator/mejed-alkoutaini/?via=mejed_k&fp_sid=morebtn"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-115a0cu", className, classNames)} framer-1xy7bjd`} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"gSmfjEWkH"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4e605a0f-b3c1-42f0-bd07-ade601eb6c1f, rgb(44, 45, 47))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 0.636953px 1.14652px -1.125px rgba(0, 0, 0, 0.26), 0px 1.9316px 3.47689px -2.25px rgba(0, 0, 0, 0.24), 0px 5.10612px 9.19102px -3.375px rgba(0, 0, 0, 0.19), 0px 16px 28.8px -4.5px rgba(0, 0, 0, 0.03)", ...style}} {...addPropertyOverrides({LWrlPvQXM: {"data-framer-name": "Desktop (Small)"}, N9rKH0JL3: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-line-height": "27px", "--framer-text-color": "var(--extracted-a0htzi, var(--token-0c23e7ca-4b1c-4639-b5e7-c6d7ba50cdcc, rgb(255, 255, 255)))"}}>More Templates</motion.h3></React.Fragment>} className={"framer-otl4zu"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"wlmxXKNAB"} style={{"--extracted-a0htzi": "var(--token-0c23e7ca-4b1c-4639-b5e7-c6d7ba50cdcc, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JHAuM.framer-1xy7bjd, .framer-JHAuM .framer-1xy7bjd { display: block; }", ".framer-JHAuM.framer-115a0cu { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 142px; will-change: var(--framer-will-change-override, transform); }", ".framer-JHAuM .framer-otl4zu { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JHAuM.framer-115a0cu { gap: 0px; } .framer-JHAuM.framer-115a0cu > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-JHAuM.framer-115a0cu > :first-child { margin-top: 0px; } .framer-JHAuM.framer-115a0cu > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LWrlPvQXM":{"layout":["fixed","fixed"]},"N9rKH0JL3":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerL5OzOeAfU: React.ComponentType<Props> = withCSS(Component, css, "framer-JHAuM") as typeof Component;
export default FramerL5OzOeAfU;

FramerL5OzOeAfU.displayName = "More Templates";

FramerL5OzOeAfU.defaultProps = {height: 36, width: 142};

addPropertyControls(FramerL5OzOeAfU, {variant: {options: ["gSmfjEWkH", "LWrlPvQXM", "N9rKH0JL3"], optionTitles: ["Desktop", "Desktop (Small)", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerL5OzOeAfU, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})